<template>
    <section>
        <!--工具条-->
        <el-col :span="8" class="toolbar roles">
            <el-card class="box-card" style="overflow-y:auto;" :style="{maxHeight:tableHeight + 'px'}">
                <!-- <div slot="header" class="clearfix">
                    <el-form :inline="true">
                        <el-form-item label="组织机构：" width>
                            <el-cascader
                                placeholder="请选择，支持搜索功能"
                                style="width: 250px"
                                v-model="OptionTree"
                                :options="options"
                                :props="defaultProps"
                                filterable
                                change-on-select
                            ></el-cascader>
                        </el-form-item>
                        <el-button @click="getRoles" style="float: right; padding: 3px 0" type="text">查询</el-button>
                    </el-form> 
                </div> -->
                <div class="block">
                    <div v-for="o in roles" :key="o.id" @click="operate(o.id)" :class="o.id==roleid ? 'active':''"
                        class="text item role-item" v-loading="listLoading1">
                        {{o.name }}
                    </div>
                    <el-pagination
                        layout="prev, pager, next"
                        :total="pages.pageTotal"
                        :page-size="pages.pageSize"
                        @prev-click="prevPage"
                        @next-click="nextPage"
                        @current-change="handleCurrentChange"
                        style="margin-top:15px;">
                    </el-pagination>
                </div>
            </el-card>
        </el-col>
        <el-col :span="16" class="toolbar perms morechildren">
            <el-card class="box-card">
                <div slot="header" class="clearfix">
                    <span>菜单</span>
                    <el-button :loading="loadingSave" @click="setMenuPermissions" style="float: right; padding: 3px 0" type="text">{{loadingSaveStr}}</el-button>
                    <!-- <tool-bar style="float: right;width: 115px;" class="editSom" :buttonList="buttonList" @callFunction="callFunction"></tool-bar> -->
                    <!-- <div style="width: 100%;height: 16px;clear: both;margin-top:10px;">
                        <el-button :loading="loadingSave" @click="removeChooseMenuPermissions" style="float: right; padding: 3px 0" type="text">{{loadingChooseStr1}}</el-button>
                        <el-button :loading="loadingSave" @click="setChooseMenuPermissions" style="float: right; padding: 3px 0;margin-right:15px;" type="text">{{loadingChooseStr}}</el-button>
                    </div> -->
                </div>
                <div class="block">
                    <el-tree
                        style="overflow-y:auto;"
                        :style="{maxHeight:tableHeight - 100 + 'px'}"
                        :data="dataTree"
                        show-checkbox
                        node-key="id"
                        ref="tree"
                        default-expand-all
                        :expand-on-click-node="true"
                        :check-strictly="true"
                        :props="defaultProps"
                        v-loading="listLoading"
                    >
                        <span class="custom-tree-node" slot-scope="{ node, data }">
                            <span>{{ node.label }}</span>
                            <span>
                                <el-checkbox-group v-model="assignBtns">
                                    <el-checkbox v-for="btn in data.buttons" :key="btn.id"
                                        :label="btn.id.toString()">
                                        {{btn.name}}
                                    </el-checkbox>
                                </el-checkbox-group>
                            </span>
                        </span>
                    </el-tree>
                </div>
            </el-card>
        </el-col>
    </section>
</template>

<script>
import toolBar from "@/components/Toolbar";
import { getButtonList } from "../../promissionRouter";
import {getRoleListPage , getMenuTree , GetAssignMenuPermissionIdByRoleId , assignMenuPermissions , getOrganizationTree } from '../../api/api';
export default {
    components: { toolBar },
    data() {
        return{
            buttonList: [],
            tableHeight: 700,
            roles: [],
            roleid:"00",
            listLoading: false,
            listLoading1: false,
            pages: {  //关于分页的obj
                pageSize: 20,//默认每页条数
                pageIndex: 1, //默认进入页
                pageTotal: 20, //默认总条数
            },
            dataTree:[],
            loadingSaveStr:'保存',
            loadingChooseStr:'全选',
            loadingChooseStr1:'取消全选',
            loadingSave:false,
            assignBtns: [],
            defaultProps: { 
                children: "children",
                label: "name",
                value:'id'
            },
            OptionTree: [],  //组织机构数据
            options: [],
            resArr: [],
        }
    },
    methods: {
        // 点击了查询，新增，编辑，删除
        callFunction(item) {
            this[item.Func].apply(this, item);
        },
        getRoles() {
            this.getRoleListPage();
        },
        operate(id) {
            if(id !=="00"){
                this.loadingSave=true;
                this.loadingSaveStr='加载中...';
                this.roleid = id;
                var params = {
                    roleId: this.roleid
                }
                this.getMenuByRoleId(params);
            }
        },
        //获取角色信息分页列表
        getRoleListPage(){
            var params = {
                pageIndex: this.pages.pageIndex,
                pageSize: this.pages.pageSize,
            }
            if(this.OptionTree.length > 0){
                params.organizationInfoID = this.OptionTree[this.OptionTree.length-1];
            }
            this.listLoading1 = true
            getRoleListPage(params).then(res => {
                var data = res.data
                this.listLoading1 = false
                if(data.success){
                    if(data.response.data.length > 0){
                        this.roles = data.response.data
                        // this.pages.pageSize = data.response.pageSize
                        this.pages.pageTotal = data.response.dataCount
                    }else{
                        this.roles = [{
                            ID:"00",
                            Name:"暂无数据..."
                        }]
                    }
                }else{
                    this.$message({
                        message: data.message,
                        type: "error"
                    });
                }
            })
            .catch(err => {
                this.listLoading1 = false
                // this.$message({
                //     message: "服务器出小差了！",
                //     type: "error"
                // });
            })
        },
        // 分页
        handleCurrentChange(val) {
            this.pages.pageIndex = val;
            this.getRoleListPage();
        },
        prevPage(val) {
            this.pages.pageIndex = val;
            this.getRoleListPage();
        },
        nextPage(val) {
            this.pages.pageIndex = val;
            this.getRoleListPage();
        },
        // 获取菜单
        getDataTree() {
            this.listLoading = true
            getMenuTree().then(res => {
                var data = res.data
                this.listLoading = false
                if(data.success){
                    var result = []
                    if(data.response.name == "根节点"){
                        this.dataTree = data.response.children
                    }else{
                        result.push(data.response)
                        this.dataTree = result
                    }
                }else{
                    this.$message({
                        message: data.message,
                        type: "error"
                    });
                }
            })
            .catch(err => {
                this.listLoading = false
                // this.$message({
                //     message: "服务器出小差了！",
                //     type: "error"
                // });
            })
        },
        // 选中了树的某一个节点 有bug暂缓修改
        getCheckedKeys(row) {
            var rowArr = row.buttons
            for(var a = 0; a < rowArr.length; a++){
                if(this.resArr.length > 0){
                    for(var i = 0;i < this.resArr.length;i++){
                        if(this.resArr[i] == rowArr[a].ID){
                            this.resArr.splice(i,1);
                            break;
                        }else{
                            this.resArr.push(rowArr[a].ID)
                            break;
                        }
                    }
                }else{
                    this.resArr.push(rowArr[a].ID)
                }
            }
            this.assignBtns = (this.resArr).map(String);
        },
        // 全选
        setChooseMenuPermissions() {
            var chooseResArrTree = []
            var chooseResArrbutt = []
            this.dataTree.map((res) => {
                chooseResArrTree.push(res.ID)
                if(res.children){
                    res.children.map((data) => {
                        chooseResArrTree.push(data.ID)
                        if(data.children){
                            data.children.map((data1) => {
                                chooseResArrTree.push(data1.ID)
                            })
                        }
                    })
                }
                if(res.buttons && res.buttons.length > 0){
                    res.buttons.map((data) => {
                        chooseResArrbutt.push(data.ID)
                        if(data.buttons){
                            data.buttons.map((data1) => {
                                chooseResArrbutt.push(data1.ID)
                            })
                        }
                    })
                }else{
                    if(res.children && res.children.length > 0){
                        res.children.map((data1) => {
                            if(data1.buttons && data1.buttons.length > 0){
                                data1.buttons.map((data2) => {
                                    chooseResArrbutt.push(data2.ID)
                                    if(data2.children && data2.children.length > 0){
                                        data2.children.map((data3) => {
                                            if(data3.buttons && data3.buttons.length > 0){
                                                data1.buttons.map((data4) => {
                                                   chooseResArrbutt.push(data4.ID)
                                                })
                                            }
                                        })
                                    }
                                })
                            }
                        })
                    }
                }
            })
            this.$refs.tree.setCheckedKeys(chooseResArrTree);
            this.assignBtns = (chooseResArrbutt).map(String);
        },
        // 取消全选
        removeChooseMenuPermissions() {
            this.$refs.tree.setCheckedKeys([]);
            this.assignBtns = ([]);
        },
        // 获取roleID
        getMenuByRoleId(params) {
            this.assignBtns = [];
            GetAssignMenuPermissionIdByRoleId(params).then(res => {
                var data = res.data
                if(data.success){
                    this.loadingSave=false;
                    this.loadingSaveStr='保存';
                    this.$refs.tree.setCheckedKeys(data.response.menuPermissionsIds);
                    this.assignBtns = (data.response.btnPermissionsIds).map(String);
                }else{
                    this.$message({
                        message: data.message,
                        type: "error"
                    });
                }
            })
            .catch(err => {
                // this.$message({
                //     message: "服务器出小差了！",
                //     type: "error"
                // });
            })
        },
        // 获取树结构数据
        getOrganizations() {
            var _this = this
            var ID = JSON.parse(localStorage.getItem("user")).ID
            var params = {
                parentId: 0
            }
            _this.options = []
            getOrganizationTree(params).then(res => {
                var data = res.data
                var resData = [data.response]
                if(data.success){
                    if(data.response.name == "根节点"){
                        _this.options = data.response.children
                    }else{
                        _this.options = resData
                    }
                }
            })
        },
        // 保存
        setMenuPermissions() {
            let _this=this;
            this.loadingSave=true;
            this.loadingSaveStr='保存中...';
            let pids = this.$refs.tree.getCheckedKeys();
            try {
                if (this.assignBtns.length > 0) {
                    for (let i = 0; i < this.assignBtns.length; i++) {
                        let assginbtn = this.assignBtns[i];
                        if (assginbtn && assginbtn > 0) {
                            pids.push(assginbtn);
                        }
                    }
                }
            } catch (e) {

            }
            if(this.roleid == "00"){
                this.$message({
                    message: "请选择角色进行菜单权限分配！",
                    type: "warning"
                });
                this.loadingSave=false;
                this.loadingSaveStr='保存';
                return false;
            }
            var pidsc = pids.map(Number);
            let params = {menuPermissionsIds: pidsc, roleId: this.roleid}
            assignMenuPermissions(params).then(res => {
                var data = res.data
                if(data.success){
                    this.$message({
                        message: data.message,
                        type: "success"
                    });
                    var params = {
                        roleId: this.roleid
                    }
                    this.getMenuByRoleId(params);
                }else{
                    this.$message({
                        message: data.message,
                        type: "error"
                    });
                    setTimeout(function(){
                        _this.loadingSave=false;
                        _this.loadingSaveStr='保存';
                    },3000)
                }
            })
            .catch(err => {
                this.$message({
                    message: "保存出错了！",
                    type: "error"
                });
                setTimeout(function(){
                    _this.loadingSave=false;
                    _this.loadingSaveStr='保存';
                },3000)
            })
        },
    },
    created() {
        this.getRoleListPage();
        this.getDataTree();
        this.getOrganizations();
    },
    mounted() {
        let routers = window.localStorage.router
        ? JSON.parse(window.localStorage.router)
        : [];
        this.buttonList = getButtonList(this.$route.path, routers);
    },
    updated() {
        var tableHeight = document.getElementById('app').offsetHeight - 92 - 30
        this.tableHeight = tableHeight
    },
}
</script>

<style lang="stylus" scoped>
    .role-item {
        cursor: pointer;
        padding: 10px;
    }

    .role-item.active {
        background: #ebf5ff;
    }

    .role-item:hover {
        background: #ebf5ff;
    }

    .custom-tree-node {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 14px;
        padding-right: 8px;
    }

    .text {
        font-size: 14px;
    }

    .clearfix:before,
    .clearfix:after {
        display: table;
        content: "";
    }

    .clearfix:after {
        clear: both
    }

    .box-card {
        width: 90%;
    }


    .morechildren .el-checkbox{
        margin-right: 5px !important;
        float: left;
    }
    .morechildren .el-checkbox-group{
        margin-left: 50px;
        padding: 5px;
    }
    .morechildren .el-tree-node__content{
        height: auto !important;
    }
    .block>>>.el-tree-node__content{
        height auto;
    }
</style>